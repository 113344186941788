import { Controller } from "stimulus";
import Plyr from "plyr";

export default class extends Controller {
  connect() {
    this.player = new Plyr(this.element.querySelector(".js-player"), {
      controls: ["play", "progress", "current-time", "mute", "volume"]
    });
  }

  restart(event) {
    event.currentTarget.innerHTML = "Recommencer";
    this.player.restart();
    this.player.play();
  }
}
