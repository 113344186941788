import { Controller } from "stimulus"
const isBlank = require('is-blank')

export default class extends Controller {
  next(event) {
    const form = event.currentTarget.closest("form")
    const current = event.currentTarget.closest(".step")
    const href = event.currentTarget.getAttribute('href')
    const target = document.querySelector(href)

    event.preventDefault()

    var even = function(element) {
      return true
    }

    if (current.getAttribute('id') == "savouring-0") {
      const selector = form.querySelectorAll("textarea,input[type=text]")
      const textareas = [].slice.call(selector)
      const empty = textareas.some(function(element){
        return isBlank(element.value)
      })
      if (empty) {
        alert("Veuillez remplir tous les champs.")
        return
      } else {
        selector.forEach(function(element) {
          element.readOnly = true
        })
      }
    }
    current.style.display = 'none'
    target.style.display = 'block'
  }
}
