import { Controller } from "stimulus";
var Slider = require("bootstrap-slider");

export default class extends Controller {
  connect() {
    this.slider_input = new Slider("#" + this.element.id)
    this.slider_input.relayout()
  }

  refresh() {
    this.slider_input.refresh()
  }
}
