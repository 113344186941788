import { Controller } from "stimulus";
import { polyfill } from "mobile-drag-drop";
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";
import "mobile-drag-drop/default.css";

export default class extends Controller {
  static targets = ["fixedImage", "draggableImage"];

  connect() {
    this.despIsActive = false;
    this.savouringTimeout = 3500;
    this.stepCount = this.element.getElementsByClassName("desp-step").length;

    this.imageReceiverElm = document.getElementById("desp-image-receiver");
    this.despElm = document.getElementById("desp");

    this.startStep(0);

    polyfill();
  }

  nextBtn(e) {
    e.preventDefault();
    this.nextStep();
  }

  resize() {
    const windowHeight = window.innerHeight;
    const despHeight = this.despElm.offsetHeight;

    if ( windowHeight < despHeight) {
      const maxWidth = this.despElm.offsetWidth * (windowHeight / despHeight);
      this.despElm.style.maxWidth = maxWidth + "px";
    } else {
      this.despElm.style.removeProperty("max-width");
    }
  }

  startDesp(tryNumber = 0) {
    this.despIsActive = true;
    this.savouringStep = 0;
    this.enableKeyPress = false;
    this.currentTry = tryNumber;

    const currentStepElm = document.getElementById(
      `desp-step-${this.currentStep}`
    );

    this.imageReceiverElm.classList.remove("highlighted");
    this.imageReceiverElm.setAttribute(
      "src",
      this.imageReceiverElm.dataset.blank
    );

    const images = JSON.parse(currentStepElm.dataset.images)[tryNumber];

    // console.log(`--- startDesp (try ${tryNumber})--------------`)

    images.forEach(function(image, index) {
      const imageElm = document.getElementById(`desp-image-${index}`);
      imageElm.setAttribute("src", imageElm.dataset.blank);
      imageElm.setAttribute("src", image.url);
      imageElm.dataset.src = image.url;
      imageElm.setAttribute("draggable", image.orientation == "positive");
    });
    document.getElementById("savouring-step-0").style.display = "none";
    document.getElementById("savouring-step-4").style.display = "none";

    currentStepElm.getElementsByClassName("intro-message")[0].style.display =
      "none";
    this.despElm.style.display = "block";
    this.resize()
    this.despElm.scrollIntoView({behavior: "smooth", block: "start"});
  }

  endDesp() {
    this.despIsActive = false;
    this.startStep(this.currentStep + 1);
  }

  startStep(step) {
    this.currentStep = step;
    this.despElm.style.display = "none";

    if (this.currentStep < this.stepCount) {
      const currentStepElm = document.getElementById(
        `desp-step-${this.currentStep}`
      );
      currentStepElm.getElementsByClassName("intro-message")[0].style.display =
        "block";
      this.tryCount = JSON.parse(currentStepElm.dataset.images).length;
      this.enableKeyPress = true;
    } else {
      const finalStepElm = document.getElementById("desp-end-message");
      finalStepElm.getElementsByClassName("intro-message")[0].style.display =
        "block";
    }
  }

  nextStep() {
    if (this.despIsActive) {
      if (this.savouringStep == 0) {
        this.displaySavouringStep(1);
        setTimeout(() => this.displaySavouringStep(2), this.savouringTimeout);
        setTimeout(
          () => this.displaySavouringStep(3),
          this.savouringTimeout * 2
        );
        setTimeout(
          () => this.displaySavouringStep(4),
          this.savouringTimeout * 3
        );
      }
    } else {
      this.startDesp();
    }
  }

  displaySavouringStep(step) {
    this.savouringStep = step;
    if (this.currentStep == 1) {
      const longInstructions = this.element.getElementsByClassName(
        "long-instruction"
      );
      Array.prototype.forEach.call(longInstructions, function(element) {
        element.style.display = "none";
      });
      const shortInstructions = this.element.getElementsByClassName(
        "short-instruction"
      );
      Array.prototype.forEach.call(shortInstructions, function(element) {
        element.style.display = "block";
      });
    }
    if (step == 0) {
      this.enableKeyPress = true;
      document.getElementById("savouring-step-4").style.display = "none";
    } else {
      this.enableKeyPress = false;
      document.getElementById(`savouring-step-${step - 1}`).style.display =
        "none";
      if (step == 1) this.imageReceiverElm.classList.add("highlighted");
      if (step == 4) this.refreshSlider();
    }
    document.getElementById(`savouring-step-${step}`).style.display = "block";
  }

  endofsavouring(event) {
    event.preventDefault();
    if (this.currentTry + 1 < this.tryCount) {
      this.startDesp(this.currentTry + 1);
    } else {
      this.endDesp();
    }
  }

  refreshSlider() {
    const slider = document.getElementById("savouring-score");
    const sliderController = this.application.getControllerForElementAndIdentifier(
      slider,
      "slider"
    );
    sliderController.refresh();
  }

  dragstart(event) {
    event.dataTransfer.setData("text/plain", event.target.dataset.src);
    event.dataTransfer.setData(
      "application/drag-key",
      event.target.getAttribute("id")
    );
    event.dataTransfer.effectAllowed = "move";
  }

  dragover(event) {
    event.preventDefault();
  }

  dragenter(event) {
    event.preventDefault();
  }

  dragend(event) {
    event.preventDefault();
  }

  drop(event) {
    event.preventDefault();
    const url = event.dataTransfer.getData("text/plain");
    const sourceId = event.dataTransfer.getData("application/drag-key");
    const sourceElm = document.getElementById(sourceId);
    if (sourceElm !== null) {
      event.target.setAttribute("src", url);
      sourceElm.setAttribute("src", sourceElm.dataset.blank);
      this.displaySavouringStep(0);
    }
  }
}
