import "js-datepicker/dist/datepicker.min.css";
import datepicker from "js-datepicker";
const dateFormat = require("dateformat");

document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll(".datepicker").forEach(function(datePickerElm) {
    datepicker(datePickerElm, {
      customDays: ["D", "L", "Ma", "Me", "J", "V", "S"],
      customMonths: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
      ],
      formatter: (input, date, instance) => {
        input.value = dateFormat(date, "dd/mm/yyyy");
      },
      startDay: 1
    });
  });
});
